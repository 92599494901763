// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-tag-best-practices-js": () => import("./../../../src/pages/tag-best-practices.js" /* webpackChunkName: "component---src-pages-tag-best-practices-js" */),
  "component---src-pages-tag-editor-article-js": () => import("./../../../src/pages/tag-editor-article.js" /* webpackChunkName: "component---src-pages-tag-editor-article-js" */),
  "component---src-pages-tag-types-js": () => import("./../../../src/pages/tag-types.js" /* webpackChunkName: "component---src-pages-tag-types-js" */),
  "component---src-pages-tag-use-cases-js": () => import("./../../../src/pages/tag-use-cases.js" /* webpackChunkName: "component---src-pages-tag-use-cases-js" */),
  "component---src-pages-tagbot-article-js": () => import("./../../../src/pages/tagbot-article.js" /* webpackChunkName: "component---src-pages-tagbot-article-js" */),
  "component---src-pages-tagging-basics-js": () => import("./../../../src/pages/tagging-basics.js" /* webpackChunkName: "component---src-pages-tagging-basics-js" */)
}

